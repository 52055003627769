import React from "react";
const Footer = () => {
  return (
    <div className="py-5 mt-3  bg-amber-50 flex flex-wrap justify-center items-center gap-2 md:gap-10  absolute right-0 left-0 ">
      <p className="flex gap-3">
      </p>
    </div>
  );
};

export default Footer;
